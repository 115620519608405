import React from 'react'
import Layout from '../components/misc/Layout'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import SliceZone from '../components/misc/PrismicSliceZone'

export const queryHome = graphql`
  {
    prismic {
      pagina_de_inicio(lang: "es-es", uid: "inicio") {
        _meta {
          uid
        }
        body {
          ... on PRISMIC_Pagina_de_inicioBodyImagen {
            type
            label
            primary {
              imagen
            }
          }
          ... on PRISMIC_Pagina_de_inicioBodyTexto_destacado {
            type
            label
            primary {
              texto
            }
          }
          ... on PRISMIC_Pagina_de_inicioBodyGrupo_de_categorias {
            type
            label
            fields {
              categoria {
                _linkType
                ... on PRISMIC_Pagina_de_producto {
                  titulo_del_documento
                  subtitulo_del_documento
                  imagen_destacada
                  _meta {
                    uid
                    type
                  }
                }
              }
            }
          }
          ... on PRISMIC_Pagina_de_inicioBodyTitulo_banda {
            type
            label
            primary {
              titulo
            }
          }
          ... on PRISMIC_Pagina_de_inicioBodyTexto_enriquecido {
            type
            label
            fields {
              contenido_texto_enriquecido
            }
          }
        }
      }
    }
  }
`

const Inicio = ({ data }) => {
  if (!!data && data.prismic && !data.prismic.pagina_de_inicio) return null
  const { body } = data.prismic.pagina_de_inicio
  return (
    <Layout>
      <SliceZone sliceZone={body}></SliceZone>
    </Layout>
  )
}

const InicioWithQuery = () => (
  <StaticQuery
    query={queryHome}
    render={withPreview(
      data => (
        <Inicio data={data} />
      ),
      queryHome
    )}
  />
)

export default InicioWithQuery
